<template>
    <div>
        <!-- Модальное окно при восстановлении сертификата -->
        <div class="modal is-active" v-if="reissueModalActive">
            <div class="modal-background" v-on:click="openReissueModal"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{ $t('Insert the security token') }}</p>
                    <figure class="image is-48x48">
                        <img src="@/assets/img/insert_usb.gif" alt="Image">
                    </figure>
                </header>
                <section class="modal-card-body">
                    <p>{{ $t('Connect the security token to a USB port. Do not remove it before the end of operation') }}.</p>
                    <div style="height: 0.75em;"></div>
                    <div class="field">
                        <label class="label">{{ $t('Enter your order number') }}*</label>
                        <div class="control">
                            <input v-model.trim="order" name="order" v-validate="'required|orderReissue'"
                                   data-vv-validate-on="'change'" class="input" :class="{'has-error': errors.has('order')}"
                                   type="text" placeholder="">
                            <p v-if="errors.has('order')" class="alert-error ">{{errors.first('order')}}</p>
                        </div>
                        <p class="help">{{ $t('Specify the order number for Reissue') }}.</p>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" type="button" v-on:click="checkReissue">{{ $t('Reissue a digital signature certificate') }}</button>
                    <button class="button" type="button" v-on:click="openReissueModal">{{ $t('Cancel') }}</button>
                </footer>
            </div>
        </div>

      <!-- Модальное окно предварительной информации по подготовке к выпуску тестового сертификата ыфы-->
      <div class="modal is-active" v-if="showInfoModal">
        <div class="modal-background" @click="openInfoModal"></div>
        <div class="modal-card" style="width: 720px">
          <header class="modal-card-head">
            <p style="text-align: center; font-weight: 600;" class="modal-card-title">
              {{ $t('To purchase a digital signature certificate, you need') }}
            </p>
          </header>
          <section class="modal-card-body">
            <div class="container">
              <div style="height:0.75rem;"></div>
              <div class="content">
                <div class="columns is-centered">
                  <div class="column card" style="background: #f7f7f7">
                    <div style="height:1rem;"></div>
                    <div class="columns">
                      <div class="column is-1">
                        <img style="max-height: 50px;" class="image" src="@/assets/icons/usb.svg">
                      </div>
                      <div v-if="lang == 'ru' || lang == 'en'" class="column is-center div-text">
                        1. {{ $t('Purchase a') }} <a v-on:click="this.openKeyModal">{{ $t('recommended security token') }}</a>
                      </div>
                      <div v-else class="column is-center div-text">
                        1. <a v-on:click="this.openKeyModal">{{ $t('recommended security token') }}</a> {{ $t('Purchase a') }}.
                      </div>
                    </div>
                    <div style="height:1rem;"></div>
                    <div class="columns">
                      <div class="column is-1">
                        <img class="image" src="@/assets/icons/computer.svg" style="max-height: 50px">
                      </div>
                      <div v-if="lang == 'ru' || lang == 'en'" class="column is-center div-text">
                        2. {{ $t('Download and install') }} <a @click="openSoftwareModal">{{ $t('software') }}</a> {{ $t('to operate with your security token') }}.
                      </div>
                      <div v-else class="column is-center div-text">
                        2. {{ $t('to operate with your security token') }} <a @click="openSoftwareModal">{{ $t('software') }}</a> {{ $t('Download and install') }}.
                      </div>
                    </div>
                    <div style="height:1rem;"></div>
                  </div>
                </div>

              </div>
              <div></div>
            </div>
<!--            <div style="height: 1em;"></div>-->
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" type="button" @click="openOrderModalTest">{{ $t('Continue') }}</button>
            <button class="button" type="button" @click="openInfoModal">{{ $t('Cancel') }}</button>
          </footer>
        </div>
      </div>

      <!-- Модальное окно - Рекомендуемый ключевой носитель -->
      <div class="modal is-active" v-if="keyModalActive">
        <div class="modal-background" v-on:click="closeKeyModal"></div>
        <div class="modal-card">
          <header class="modal-card-head"><p class="modal-card-title">{{ $t('Recommended security token') }}</p>
            <figure class="image is-48x48">
              <img src="@/assets/icons/usb.svg" alt="Image">
            </figure>
          </header>
          <!--                <pre>{{selectedCountryId}}</pre>-->
          <!--                <pre>{{selectedCountryShortName}}</pre>-->
          <!--                <pre style="height: 400px; overflow: auto">{{countries}}</pre>-->
          <section class="modal-card-body">
            <div class="has-text-centered">
              <p>{{ $t('Select the country of residence of your company') }}:</p>
              <div style="height: 1em;"></div>
              <div class="columns is-centered has-text-centered">
                <div class="column is-6">
                  <div class="select is-fullwidth">
                    <select name="country" v-model="selectedCountryId">
                      <option v-for="(country, index) in countries" :key="index" :value="country.id">{{country.name}}</option>
                    </select>
                  </div>
                  <div style="height: 2em;"></div>
                </div>
              </div>
              <div class="columns has-text-centered">
                <div class="column" v-if="selectedCountryId != 3">
                  <figure class="image is-96x96 is-center"><img src="@/assets/img/part_aknur.png"></figure>
                  <a href="http://aknur.kz/etoken-dlya-samruk-kazyna/etoken-dlya-polzovateley-nerezidentov-isez-ao-fnb-samruk-kazyna.html?lang=ru" target="_blank">SafeNet eToken 5110</a>
                </div>
                <div class="column" v-if="selectedCountryId == 3">
                  <figure class="image is-96x96 is-center"><img src="@/assets/img/part_alladin2.png"></figure>
                  <a href="http://aetp.ru/Html.aspx?ContentType=HtmlItem&ItemId=1828" target="_blank">JaCarta PKI/ГОСТ</a>
                </div>
                <div class="column" v-if="selectedCountryId == 42">
                  <figure class="image is-96x96 is-center"><img src="@/assets/img/part_alladin.png"></figure>
                  <a href="http://www.ealaddin.com.ua/7-novosti/78-autentifikatsiya-na-torgovykh-ploshchadkakh.html" target="_blank">SafeNet eToken 5110</a>
                </div>
<!--                <div class="column" v-if="selectedCountryId != 3">-->
<!--                  <figure class="image is-96x96 is-center"><img src="@/assets/img/part_iepir.png"></figure>-->
<!--                  <a href="https://iepirkumi.lv/ru/drugije_servisi/kazahstan" target="_blank">JaCarta JC 100 </a>-->
<!--                </div>-->
                <div class="column" v-if="selectedCountryId != 3">
                  <figure class="image is-96x96 is-center"><img src="@/assets/img/part_thales.jpg" style="border: 1px solid rgb(208, 209, 209); padding: 40px 5px 40px; border-radius: 10px;"></figure>
                  <a href="https://cpl.thalesgroup.com/access-management/authenticators/pki-usb-authentication/etoken-5110-usb-token" target="_blank">SafeNet eToken 5110+</a>
                </div>
              </div>
            </div>
            <div style="height: 1em;"></div>
          </section>
          <footer class="modal-card-foot">
            <a class="button is-primary" v-on:click="closeKeyModal">{{ $t('Close') }}</a>
          </footer>
        </div>
      </div>

      <!-- Модальное окно - Установка программного обеспечения -->
      <div class="modal is-active" v-if="softwareModalActive">
        <div class="modal-background" v-on:click="closeSoftwareModal"></div>
        <div class="modal-card">
          <header class="modal-card-head"><p class="modal-card-title">{{ $t('Software installation') }}</p>
            <figure class="image is-48x48">
              <img src="@/assets/icons/computer.svg" alt="Image">
            </figure>
          </header>
          <section class="modal-card-body">
            <p>1. {{ $t('Security token software') }}:</p>
            <ul style="list-style-type: circle; padding: 5px 30px;">
              <li style="padding: 5px 0;"><b>JaCarta PKI/ГОСТ</b> - <a href="https://www.aladdin-rd.ru/support/downloads/jacarta_client/" target="_blank">{{ $t('JaCarta Unified Client') }}</a></li>
              <li style="padding: 5px 0;"><b>{{ $t('SafeNet eToken 5110') }}</b> <span v-html="$t('contact 2')"></span></li>
            </ul>
            <div style="height: 0.2em;"></div>
            <p>2. {{ $t('Software 1') }} <a v-bind:href="'/application/distr/SetupCryptoSocket_' + lang + '.zip?v=1.0.11.1212'">{{ $t('CryptoSocket') }}</a>{{ $t('Software 2') }}</p>
            <div style="height: 0.5em;"></div>
<!--            <p>3. <a href="/application/distr/InstallCA_rus.exe">{{ $t('PKI Root Certificate Installation Wizard') }}</a></p>-->
            <div style="height: 1em;"></div>
          </section>
          <footer class="modal-card-foot">
            <a class="button is-primary" v-on:click="closeSoftwareModal">{{ $t('Close') }}</a>
          </footer>
        </div>
      </div>

        <!-- Модальное окно для тестового сертификата -->
        <div class="modal is-active" v-if="orderModalTestActive">
            <div class="modal-background" v-on:click="openOrderModalTest"></div>
            <div class="modal-card">
                <header class="modal-card-head"><p class="modal-card-title">{{ $t('Connect the security token') }}</p>
                    <figure class="image is-48x48">
                        <img src="@/assets/img/insert_usb.gif" alt="Image">
                    </figure>
                </header>
                <section class="modal-card-body">
                    <p class="p_modal">{{ $t('Please, make sure that the USB port of your computer is connected with only one security token') }}. {{ $t('Do not remove it before the end of operation') }}.</p>
                    <div style="height: 1em;"></div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" type="button" v-on:click="GoToTest">{{ $t('Continue') }}</button>
                    <button class="button" type="button" v-on:click="openOrderModalTest">{{ $t('Cancel') }}</button>
                </footer>
            </div>
        </div>

        <section class="section">
            <div class="container">
                <div class="columns is-centered">
                    <div class="column is-3 has-background-light">
                        <aside class="menu">
                            <p class="menu-label">{{ $t('Instructions') }}</p>
                            <ul class="menu-list is-size-7">
                                <!--                <li><a :class="{'is-active': isActive(1), 'has-background-primary': isPrimary(1)}" v-on:click="IIW()">{{ $t('Install root certificates in Windows') }}</a></li>-->
<!--                                <li><a :class="{'is-active': isActive(2), 'has-background-primary': isPrimary(2)}" v-on:click="IIB()">{{ $t('Install root certificates') }}</a></li>-->
                                <li><a :class="{'is-active': isActive(3), 'has-background-primary': isPrimary(3)}" v-on:click="SmartCard()">{{ $t('Start Smart Card Service') }}</a></li>
                                <li><a :class="{'is-active': isActive(4), 'has-background-primary': isPrimary(4)}" v-on:click="Browsers()">{{ $t('How to check version and configure web browser') }}</a></li>
                                <!--                <li><a :class="{'is-active': isActive(5), 'has-background-primary': isPrimary(5)}" v-on:click="JaCarta()">{{ $t('Unlock device JaCarta') }}</a></li>-->
                                <!--                <li><a :class="{'is-active': isActive(6), 'has-background-primary': isPrimary(6)}" v-on:click="ETokenPro()">{{ $t('Initialization of eToken PRO 72k (Java)') }}</a></li>-->
                                <li><a @click="openReissueModal">{{ $t('Reissue a digital signature certificate') }}</a></li>
                                <li><a @click="openInfoModal">{{ $t('Issue a test digital signature certificate') }}</a></li>
                                <li><a @click="downloadRevList('GOST')">{{ $t('Download GOST Certificate Revocation List') }}</a></li>
                                <li><a @click="downloadRevList('RSA')">{{ $t('Download RSA Certificate Revocation List') }}</a></li>
                            </ul>
                        </aside>
                    </div>

                    <IIW v-if="form === 'IIW' && !stepMain"/>
                    <IIB v-if="form === 'IIB' && !stepMain"/>
                    <SmartCard v-if="form === 'SmartCard' && !stepMain"/>
                    <Browsers v-if="form === 'Browsers' && !stepMain"/>
                    <JaCarta v-if="form === 'JaCarta' && !stepMain"/>
                    <ETokenPro v-if="form === 'ETokenPro' && !stepMain"/>

                </div>
            </div>
        </section>

    </div>
</template>

<script>
import IIW from '../instructions/IIW'
import IIB from '../instructions/IIB'
import SmartCard from '../instructions/SmartCard'
import Browsers from '../instructions/Browsers'
import JaCarta from '../instructions/JaCarta'
import ETokenPro from '../instructions/ETokenPro'
export default {
  name: 'Instructions',
  components: {
    IIW,
    IIB,
    SmartCard,
    Browsers,
    JaCarta,
    ETokenPro
  },
  data () {
    return {
      textActive: [3],
      textPrimary: [3],
      form: 'SmartCard',
      result: null,
      reissueModalActive: false,
      orderModalTestActive: false,
      showInfoModal: false,
      keyModalActive: false,
      softwareModalActive: false,
      countries: null,
      selectedCountryId: null,
      order: null,
      templates: null,
      reissue: null,
      templateId: 0,
      myTemplate: null,
      myPrice: 0,
      currencyId: 4,
      myCurrency: 'KZT'
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    step () {
      return this.$route.params.step
    },
    stepMain () {
      return this.$store.state.instructionsStep
    },
    template () {
      return this.templates ? this.templates.find(item => item.template_name === 'SAMRUK_1Y') : null
    }
  },
  watch: {
    templates: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/templates/').then(response => {
            this.templates = response.data
          })
        }
      },
      immediate: true
    },
    countries: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/countries/').then(response => {
            this.countries = response.data
            this.$http.get('/query/get-country/').then(response => {
              if (response.data) {
                console.log(response.data)
                const country = this.countries ? this.countries.find(item => item.name === response.data) : null
                this.selectedCountryId = country.id
              } else {
                this.selectedCountryId = '2'
              }
            })
          })
        }
      },
      immediate: true
    },
    result: {
      handler: function (val) {
        if (!val) {
          if (this.step && this.step !== 'reissue') {
            this.form = this.step
            this.result = this.form
          }
        }
      },
      immediate: true
    },
    reissue: {
      handler: function (val) {
        if (!val) {
          if (this.step === 'reissue') {
            this.reissue = true
            this.reissueModalActive = true
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    downloadRevList (certType) {
      this.$http.get('/query/get-rev-list/', {
        params: {
          certType: certType
        }
      })
        .then((response) => {
          let blob = new Blob([response.data])
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'RevList' + certType + '.crl'
          link.click()
        })
        .catch((error) => { console.log(error) })
    },
    isActive (id) {
      return this.textActive.find(text => text === id)
    },
    isPrimary (id) {
      return this.textPrimary.find(text => text === id)
    },
    IIW () {
      this.textActive = [1]
      this.textPrimary = [1]
      this.form = 'IIW'
      this.$store.commit('updateInstructionsStep', null)
    },
    IIB () {
      this.textActive = [2]
      this.textPrimary = [2]
      this.form = 'IIB'
      this.$store.commit('updateInstructionsStep', null)
    },
    SmartCard () {
      this.textActive = [3]
      this.textPrimary = [3]
      this.form = 'SmartCard'
      this.$store.commit('updateInstructionsStep', null)
    },
    Browsers () {
      this.textActive = [4]
      this.textPrimary = [4]
      this.form = 'Browsers'
      this.$store.commit('updateInstructionsStep', null)
    },
    JaCarta () {
      this.textActive = [5]
      this.textPrimary = [5]
      this.form = 'JaCarta'
      this.$store.commit('updateInstructionsStep', null)
    },
    ETokenPro () {
      this.textActive = [6]
      this.textPrimary = [6]
      this.form = 'ETokenPro'
      this.$store.commit('updateInstructionsStep', null)
    },
    openReissueModal () {
      this.reissueModalActive = !this.reissueModalActive
    },
    openOrderModalTest () {
      this.showInfoModal = false;
      this.orderModalTestActive = !this.orderModalTestActive
    },
    openInfoModal: function () {
      this.showInfoModal = !this.showInfoModal;
    },
    closeKeyModal () {
      this.keyModalActive = false;
      this.showInfoModal = true;
    },
    openKeyModal(){
      this.keyModalActive = true;
      this.showInfoModal = false;
    },
    openSoftwareModal (){
      this.softwareModalActive = true;
      this.showInfoModal = false;
    },
    closeSoftwareModal(){
      this.softwareModalActive = false;
      this.showInfoModal = true;
    },
    checkReissue () {
      this.$http.post('/certificate/check-reissue', {
        order: this.order
      }).then(response => {
        if (response.data && response.data == 0)
          this.GoToReissue()
        else
          this.$validator.errors.add({
            field: "order",
            rule: "orderReissue",
            msg: this.$i18n.t('The specified order number did not match, or the order for reissue was accepted earlier').toString()
          })
      }).catch(error => {
        console.log(error);
        this.$validator.errors.add({
          field: "order",
          rule: "orderReissue",
          msg: this.$i18n.t('The specified order number did not match, or the order for reissue was accepted earlier').toString()
        })
      })
    },
    GoToReissue () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          window.localStorage.setItem('getCertType', 'reissue')

          if (this.currencyId == 4) {
            this.myPrice = this.myTemplate.recover_kzt
            this.myCurrency = 'KZT'
          } else if (this.currencyId == 5) {
            this.myPrice = this.myTemplate.recover_usd
            this.myCurrency = 'USD'
          } else if (this.currencyId == 6) {
            this.myPrice = this.myTemplate.recover_rur
            this.myCurrency = 'RUB'
          } else {
            this.myPrice = this.myTemplate.recover_eur
            this.myCurrency = 'EUR'
          }
          this.reissueModalActive = false
          this.$router.push('/order_cert')
          this.$store.commit('updateOrderStep', 'Certificates')
          window.localStorage.setItem('order', this.order)
          window.localStorage.setItem('OrderNumber', '')
          window.localStorage.setItem('OrderPassword', '')
          window.localStorage.setItem('price', this.myPrice)
          window.localStorage.setItem('currency', this.myCurrency)
          window.localStorage.setItem('currencyId', this.currencyId)
          window.localStorage.setItem('template', this.myTemplate.template_name)
          window.localStorage.setItem('templateId', this.myTemplate.id)
          window.localStorage.setItem('templateDescription_ru', this.myTemplate.description)
          window.localStorage.setItem('templateDescription_en', this.myTemplate.description_en)
          window.localStorage.setItem('templateDescription_kk', this.myTemplate.description_kk)
          window.localStorage.setItem('templateData', this.myTemplate.template_eussieR)
          if (this.lang === 'ru') {
            window.localStorage.setItem('templateName', this.myTemplate.description)
            window.localStorage.setItem('templateValidity', this.myTemplate.validity)
          } else if (this.lang === 'en') {
            window.localStorage.setItem('templateName', this.myTemplate.description_en)
            window.localStorage.setItem('templateValidity', this.myTemplate.validity_en)
          } else {
            window.localStorage.setItem('templateName', this.myTemplate.description_kk)
            window.localStorage.setItem('templateValidity', this.myTemplate.validity_kk)
          }
        }
      })
      console.log(this.$validator.errors);
    },
    GoToTest () {
      this.orderModalTestActive = false
      this.$router.push('/order_cert')
      this.$store.commit('updateOrderStep', 'Certificates')
      window.localStorage.setItem('order', '')
      window.localStorage.setItem('OrderNumber', '')
      window.localStorage.setItem('OrderPassword', '')
      window.localStorage.setItem('currency', 'KZT')
      window.localStorage.setItem('currencyId', '4')
      window.localStorage.setItem('price', '0')
      window.localStorage.setItem('course', '0')
      window.localStorage.setItem('template', 'Test')
      window.localStorage.setItem('templateId', '1')
      window.localStorage.setItem('templateDescription_ru', 'Тестовый сертификат')
      window.localStorage.setItem('templateDescription_en', 'Test certificate')
      window.localStorage.setItem('templateDescription_kk', 'Тесттік сертификат')
      window.localStorage.setItem('templateData', 'CN=Default;O=Template;C=KZ')
      if (this.lang === 'ru') {
        window.localStorage.setItem('templateName', 'Тестовый сертификат')
        window.localStorage.setItem('templateValidity', '1 месяц')
      } else if (this.lang === 'en') {
        window.localStorage.setItem('templateName', 'Test certificate')
        window.localStorage.setItem('templateValidity', '1 month')
      } else {
        window.localStorage.setItem('templateName', 'Тесттік сертификат')
        window.localStorage.setItem('templateValidity', '1 ай')
      }
    }
  },
  created () {
    const isValidReissue = (value) => {
      return this.$http.get('/query/get-order-info/', { params: { order: value, type: 'reissue' } }).then((response) => {
        if (response.data) {
          this.templateId = response.data.templateId
          this.currencyId = response.data.currency

          this.myTemplate = this.templates.find(item => item.id == this.templateId)
          this.$store.commit('updateMyTemplate', this.myTemplate)

          if (response.data === 1) {
            return {
              valid: false,
              data: {
                message: this.$i18n.t('Reissue of the digital signature certificate is impossible').toString()
              }
            }
          } else {
            return {
              valid: true,
              data: {
                message: ''
              }
            }
          }
        } else {
          return {
            valid: false,
            data: {
              message: this.$i18n.t('The specified order number did not match, or the order for reissue was accepted earlier').toString()
            }
          }
        }
      })
    }
    this.$validator.extend('orderReissue', {
      getMessage: (field, params, data) => {
        return data ? data.message : this.$i18n.t('The specified order number did not match, or the order for reissue was accepted earlier').toString()
      },
      validate: isValidReissue
    })
  }
}
</script>

<style scoped lang="scss">
    .is-center {
      margin: auto;
    }
    .has-background-primary {
        background-color: #339967 !important;
    }
    .is-size-7 {
        font-size: 0.81rem !important;
    }
    a {
        text-decoration: underline;
    }
    .menu-label {
        font-size: 0.81em;
        letter-spacing: 0;
        color: #363636;
    }
    .has-error {
        border-color: red;
    }
    .alert-error {
        color: red;
    }
</style>
